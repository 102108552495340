import { Image } from "./image";
import React from "react";
import ReactPlayer from 'react-player';
// <ReactPlayer style={{textAlign:'center'}} url="https://apifabric.ai/static/istart_demo.mp4" controls />

const YouTubeEmbed = ({ videoId }) => {
    return (
        <div className="video-responsive">
            <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
            />
        </div>
    );
};

export const Gallery = (props) => {
  
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Demo Video</h2>
        </div>
        <div className="row" >
          <center>
            <YouTubeEmbed videoId="7I33Fa9Ulos" />
          </center>
        </div>
      </div>
    </div>
  );
};
