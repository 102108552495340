import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              
              <div className="col-md-8 col-md-offset-2 intro-text" style={{position: "relative", top: "-4em"}}>
                <h1>
                WebGenAI
                <br/>
                </h1>
                <p className="headerParagraph" >
                  
                <a style={{color: "#555"}} href="https://genai-logic.com">genai-logic.com</a> app generator
                <br/><br/>
                <a
                  href="https://apifabric.ai/admin-app/index.html#/Home"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Open
                </a>
                <br/>
                
                </p>
                {" "}
                <p> </p>

                
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
